//import { grayscale, rgb } from "pdf-lib";
import { hexToPDFColor } from "./pdf-diagram";
import { PDFWriteHead } from "./pdf-write-head";
import { store } from "@/state/state";

export function drawWall(writeHead: PDFWriteHead, idx: number) {
  //const SVG_LOGO_WIDTH = store.walls_sizes.width;
  const SVG_LOGO_HEIGHT = store.walls_sizes.height;
  const scale = 80 / SVG_LOGO_HEIGHT;
  console.log(scale);
  let posX = 530 - store.walls_specs.xMax * scale;
  let posY = store.walls_specs.yMax * scale + writeHead.yProgress - 110;
  store.walls_polygonsZSorted.forEach((wall: any, id: number) => {
    writeHead.currentPage.drawSvgPath("M" + wall.points + "z", {
      x: posX,
      y: posY,
      scale: scale,
      borderColor: id < 3 ? hexToPDFColor("#90c0f0") : hexToPDFColor("#1f82c0"),
      borderWidth: 0.0001,
      borderOpacity: 0.8,
      opacity: idx === wall.wIdx ? 0.9 : 0.01,
      color:
        idx !== wall.wIdx
          ? hexToPDFColor("#90c0f0")
          : id < 3
          ? hexToPDFColor("#90c0f0")
          : hexToPDFColor("#1f82c0")
    });
  });
  // Enter new line after text -block:
  writeHead.goDown(SVG_LOGO_HEIGHT * scale);
}
