import { LayoutBounds } from "pdf-lib";
import { multilineTextBox } from "./pdf-multiline-textbox";
import { PDFWriteHead } from "./pdf-write-head";

export function drawParagraph(
  writeHead: PDFWriteHead,
  text: string
): LayoutBounds {
  const fontSize = writeHead.BASE_FONT_SIZE;
  const lineHeight = fontSize * 1.5;
  const textboxBounds = multilineTextBox(
    writeHead,
    text,
    writeHead.innerWidth,
    writeHead.normalFont,
    fontSize,
    lineHeight
  );
  // Enter new line after text-block:
  writeHead.goDown(lineHeight - fontSize);
  return textboxBounds;
}
