import { OctoFrequential } from "@/calc/room";
import { xAlignCenter, yAlignMiddle } from "./pdf-text-align";
import { PDFWriteHead } from "./pdf-write-head";

export function drawOctoFrequentialTableRow(
  writeHead: PDFWriteHead,
  width: number,
  cells: Readonly<OctoFrequential<string>>,
  rowHeader: string | null
) {
  const originalX = writeHead.currentPage.getX();
  const margin = 40; // adjust to diagram margin!
  const remainingWidth = width - margin * 2;
  const dataLeft = originalX + margin;
  const lineHeight = writeHead.BASE_FONT_SIZE;
  const lineSpacing = lineHeight * 0.2;
  const rowHeight = lineHeight * 2 + lineSpacing * 3;
  const textYOffset = yAlignMiddle(writeHead.normalFont, lineHeight);
  writeHead.seekDownOrBreak(rowHeight);
  writeHead.goDown(lineSpacing);
  if (rowHeader !== null) {
    writeHead.goDown(lineHeight);
    writeHead.currentPage.drawText(`${rowHeader}:`, {
      x: originalX,
      y: writeHead.yProgress + textYOffset,
      size: lineHeight,
      font: writeHead.boldFont
    });
  }
  writeHead.goDown(lineHeight + lineSpacing);

  for (let fIdx = 0; fIdx < 8; fIdx++) {
    const xPos = dataLeft + (fIdx * remainingWidth) / 7;
    const text = cells[fIdx];
    writeHead.currentPage.drawText(text, {
      x: xPos + xAlignCenter(writeHead.normalFont, lineHeight, text),
      y: writeHead.yProgress + textYOffset,
      size: lineHeight,
      font: writeHead.normalFont
    });
  }
  writeHead.goDown(lineSpacing);
  /* writeHead.currentPage.drawLine({
    start: { x: originalX, y: writeHead.yProgress },
    end: { x: originalX + width, y: writeHead.yProgress },
    thickness: 1
  }); */
}

export function drawHexaFrequentialTableRow(
  writeHead: PDFWriteHead,
  width: number,
  cells: Readonly<OctoFrequential<string>>,
  rowHeader: string | null
) {
  const originalX = writeHead.currentPage.getX();
  const margin = 40; // adjust to diagram margin!
  const remainingWidth = width - margin * 2;
  const dataLeft = originalX + margin;
  const lineHeight = writeHead.BASE_FONT_SIZE;
  const lineSpacing = lineHeight * 0.2;
  const rowHeight = lineHeight * 2 + lineSpacing * 3;
  const textYOffset = yAlignMiddle(writeHead.normalFont, lineHeight);
  writeHead.seekDownOrBreak(rowHeight);
  writeHead.goDown(lineSpacing);
  if (rowHeader !== null) {
    writeHead.goDown(lineHeight);
    writeHead.currentPage.drawText(`${rowHeader}:`, {
      x: originalX,
      y: writeHead.yProgress + textYOffset,
      size: lineHeight,
      font: writeHead.boldFont
    });
  }
  writeHead.goDown(lineHeight + lineSpacing);

  for (let fIdx = 1; fIdx < 7; fIdx++) {
    const xPos = dataLeft + (fIdx * remainingWidth) / 6;
    const text = cells[fIdx];
    writeHead.currentPage.drawText(text, {
      x: xPos + xAlignCenter(writeHead.normalFont, lineHeight, text),
      y: writeHead.yProgress + textYOffset,
      size: lineHeight,
      font: writeHead.normalFont
    });
  }
  writeHead.goDown(lineSpacing);
  /* writeHead.currentPage.drawLine({
    start: { x: originalX, y: writeHead.yProgress },
    end: { x: originalX + width, y: writeHead.yProgress },
    thickness: 1
  }); */
}

export function drawOctoFrequentialTableLine(
  writeHead: PDFWriteHead,
  width: number
) {
  writeHead.goDown(writeHead.BASE_FONT_SIZE);
  const originalX = writeHead.currentPage.getX();
  writeHead.currentPage.drawLine({
    start: { x: originalX, y: writeHead.yProgress },
    end: { x: originalX + width, y: writeHead.yProgress },
    thickness: 1
  });
}
