import { PDFFont } from "pdf-lib";

/** Calculates the y offset needed to align text to the top */

export function yAlignTop(font: PDFFont, size: number) {
  return -font.heightAtSize(size, { descender: false });
}
/** Calculates the y offset needed to align text to the middle */
export function yAlignMiddle(font: PDFFont, size: number) {
  return -font.heightAtSize(size, { descender: false }) / 2;
}
/** Calculates the y offset needed to align text to the bottom */
export function yAlignBottom() {
  return 0;
}
/** Calculates the x offset needed to align text to the left */
export function xAlignLeft() {
  return 0;
}
/** Calculates the x offset needed to align text to the center */
export function xAlignCenter(font: PDFFont, size: number, text: string) {
  return -font.widthOfTextAtSize(text, size) / 2;
}
/** Calculates the x offset needed to align text to the right */
export function xAlignRight(font: PDFFont, size: number, text: string) {
  return -font.widthOfTextAtSize(text, size);
}
