import { multilineTextBox } from "./pdf-multiline-textbox";
import { PDFWriteHead } from "./pdf-write-head";

interface HeadingMeasures {
  fontSize: number;
  lineHeight: number;
}
enum HEADING_LEVEL {
  h1 = 1,
  h2 = 2,
  h3 = 3
}
const HEADING_LEVELS: Record<HEADING_LEVEL, HeadingMeasures> = {
  1: { fontSize: 2, lineHeight: 2.25 },
  2: { fontSize: 1.75, lineHeight: 2 },
  3: { fontSize: 1.5, lineHeight: 1.75 }
};

export function drawHeading(
  writeHead: PDFWriteHead,
  text: string,
  headingLevel: HEADING_LEVEL
) {
  const { fontSize, lineHeight } = HEADING_LEVELS[headingLevel];
  multilineTextBox(
    writeHead,
    text,
    writeHead.innerWidth,
    writeHead.normalFont,
    fontSize * writeHead.BASE_FONT_SIZE,
    lineHeight * writeHead.BASE_FONT_SIZE
  );
  // Enter new line after text-block:
  writeHead.goDown(lineHeight - fontSize);
}
