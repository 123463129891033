import { PDFDocument, PDFFont, PDFPage } from "pdf-lib";
/**
 * Holds the neccessary context needed for drawing commands.
 * Provides methods that help with carriage returns and pagebreaks.
 * */
export class PDFWriteHead {
  private _pdfDoc: PDFDocument;
  private _yProgress: number;
  private _pageSize: [number, number];
  private _currentPage: PDFPage;
  private _normalFont: PDFFont;
  private _boldFont: PDFFont;
  private _padding: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  constructor(
    pdfDoc: PDFDocument,
    pageSize: [number, number],
    normalFont: PDFFont,
    boldFont: PDFFont
  ) {
    this._pdfDoc = pdfDoc;
    this._pageSize = pageSize;
    this._padding = {
      top: 56.6933,
      right: 70.8667,
      bottom: 56.6933,
      left: 70.8667
    };
    this._currentPage = this._pdfDoc.addPage(this._pageSize);
    this._currentPage.moveTo(
      this._padding.left,
      this._currentPage.getHeight() - this._padding.top
    );
    this._yProgress = this._currentPage.getY();
    this._normalFont = normalFont;
    this._boldFont = boldFont;
  }
  BASE_FONT_SIZE: Readonly<number> = 12;
  get currentPage() {
    return this._currentPage;
  }
  get pdfDoc() {
    return this._pdfDoc;
  }
  get yProgress() {
    return this._yProgress;
  }
  get innerWidth() {
    return this._pageSize[0] - this._padding.left - this._padding.right;
  }
  get paddingLeft() {
    return this._padding.left;
  }
  public get normalFont(): PDFFont {
    return this._normalFont;
  }
  public get boldFont(): PDFFont {
    return this._boldFont;
  }

  /** Returns the absolute font size for the given rem font size */
  remSize(remValue: number) {
    this.BASE_FONT_SIZE * remValue;
  }
  nextPage() {
    this._currentPage = this._pdfDoc.addPage(this._pageSize);
    this._currentPage.moveTo(
      this._padding.left,
      this._currentPage.getHeight() - this._padding.top
    );
    this._yProgress = this._currentPage.getY();
  }
  /**
   * Seek pagedown to see if the given height still fits on
   * the current page. Starts new page if not.
   * */
  seekDownOrBreak(height: number) {
    if (this._yProgress - height < this._padding.bottom) {
      this.nextPage();
    }
  }
  goDown(height: number) {
    this.seekDownOrBreak(height);
    this._yProgress -= height;
    this._currentPage.moveTo(this._currentPage.getX(), this._yProgress);
  }
}
