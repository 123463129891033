import {
  Color,
  LayoutBounds,
  layoutMultilineText,
  PDFFont,
  TextAlignment
} from "pdf-lib";
import { PDFWriteHead } from "./pdf-write-head";

export function multilineTextBox(
  writeHead: PDFWriteHead,
  text: string,
  width: number,
  font: PDFFont,
  fontSize: number,
  lineHeight: number,
  color?: Color
): LayoutBounds {
  const textBox: LayoutBounds = {
    x: writeHead.paddingLeft,
    y: writeHead.currentPage.getHeight(),
    width,
    height: 0 // zero height for easier calculation; will otherwise be substracted from y-pos
  };

  writeHead.currentPage.setLineHeight(lineHeight);
  // Make use of an existing lower level api, but
  // only use linebreaks and x-positions:
  const textLayout = layoutMultilineText(text, {
    bounds: textBox,
    alignment: TextAlignment.Left,
    fontSize: fontSize,
    font
  });
  const textLines = textLayout.lines;
  // console.log("top of page:", writeHead.currentPage.getHeight());
  // console.log("width of page:", writeHead.currentPage.getWidth());

  textLines.forEach(textLine => {
    // console.log("textline", textLine);
    // console.log("texline y", textLine.y);
    writeHead.goDown(textLayout.lineHeight);
    writeHead.currentPage.drawText(textLine.text, {
      size: textLayout.fontSize,
      lineHeight: textLayout.lineHeight,
      x: textLine.x,
      y: writeHead.yProgress,
      font,
      color
    });
  });
  /*
  // Check if writeHead was moved below text:
  writeHead.currentPage.drawCircle({
    size: 2,
    color: rgb(0.75, 0.2, 0.2),
    opacity: 1
  });
  */
  return textLayout.bounds;
}
